.po_items_ho {
    /* column-width: 15em;
    -moz-column-width: 15em;
    -webkit-column-width: 15em;
    column-gap: 4rem;
    -moz-column-gap: 4rem;
    -webkit-column-gap: 4rem; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 2rem;
}
.po_item {
    display: inline-block;
    /* width: 100%; */
    text-align: center;
    /* margin: 0.5rem auto; */
    position: relative;
    /* background: var(--secondary-color); */
    padding: 6px;
    /* border: 1px solid var(--secondary-color); */
    transition: 0.3s ease;
    font-size: 0;
    height: 20vw;
    /* width: 20vw; */    
    
    animation: .39s ease-in-out calc(0.04s * var(--var)) pop_up_animation;
    transform: scale(0);
    animation-fill-mode: forwards;    
}


@keyframes pop_up_animation {
    0% {
        /* opacity: 0; */
        transform: scale(0);
    }
    100% {
        /* opacity: 1; */
        transform: scale(1);
    }
}

@media (max-width: 999px) {
    .po_items_ho {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .po_item {
        height: 30vw;
    }
}

@media (max-width: 650px) {
    .po_items_ho {
        grid-template-columns: 1fr 1fr;
    }
    .po_item {
        height: 44vw;
    }
}

@media (max-width: 450px) {
    .po_items_ho {
        grid-template-columns: 1fr;
    }
    .po_item {
        height: 77vw;
    }
}




.po_item img {
    /* position: absolute; */
    margin: auto;
    max-width: 100%;
    max-height: 87%;
    /* top: 0; */
    /* bottom: 30px; */
    /* left: 0; */
    /* right: 0; */
}

.po_item .content {
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    /* opacity: 0; */
    border: 1px solid black;
    /* transition-delay: 1s; */
    font-size: 16px;
}

/* On Hover */

.po_item:hover {
    box-shadow: 0px 0px 20px 6px #39c5bb;
}
[data-theme="light"] .po_item:hover {
    box-shadow: 0 10px 15px 0px rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    opacity: 1;
    color: var(--secondary-color);
    background-color: var(--overlay-color);
}

.po_item .content p {
    transition: all 0.3s ease-in-out;
    position: absolute;
    bottom: 0;
}

.po_item .content button {
    background: var(--bg-color);
    border: solid 1px var(--text-color);
    color: var(--text-color);
    padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
    opacity: 0;
    transition: all 0.48763s;
    text-decoration: none;
}

.po_item:hover .content p {
    font-size: 18px;
}

.po_item:hover .content button {
    opacity: 1;
}





/* -------------------------------------------------------------------------- */
.modal-content{
    background-color: var(--bg-color);
}

.modal-img{
    max-width: 100%;
    max-height: 50vh;
}

/* -------------------------------------------------------------------------- */

.portfolio-item
{
    position: fixed;
    top: 8vh;
    bottom: 3vh;
    left: 3vw;
    width: 94vw;
    background-color: var(--overlay-color-2);

    transform: scale(0);
    transition: transform .3s ease-out;
    /* animation: .39s ease-in-out pop_up_animation; */
    /* animation-fill-mode: forwards;  */
}

.open
{
    transform: scale(1);
}

.portfolio-item::before
{
    position: fixed;
    top: -5px;
    left: -17px;
    content: '「';
    font: 45px bold;
}

.portfolio-item::after
{
    position: fixed;
    bottom: -5px;
    right: -17px;
    content: '」';
    font: 45px bold;
}
