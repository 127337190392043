
/* Light/Dark Theme */
[data-theme="dark"] *,
:root, 
::after, 
::before {
    --bg-color: #0c0c0c;
    --primary-color: #0d0d0d;
    --secondary-color: #fff;
    --text-color: #fff;
    --text-color-2: #fff;
    --overlay-color: rgb(12 12 12 / 63%);
    --overlay-color-2: rgba(39, 39, 39, 0.876);
}

[data-theme="light"] *, 
[data-theme="light"] ::after,
[data-theme="light"] ::before
{
    --bg-color: #ffffff;
    --primary-color: #ffffff;
    --secondary-color: #000;
    --text-color: #000;
    --text-color-2: #000;
    --overlay-color: rgb(255 255 255 / 70%);
    --overlay-color-2: rgba(240, 240, 240, 0.876)
}

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC&display=swap');

/* CSS */
html,
body {
    height: 100%;    
}

body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    background-color: var(--bg-color);
    color: var(--text-color);
    font-family: 'Noto Serif TC', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: 60px;
    border-left: 10px solid var(--primary-color);
    border-right: 10px solid var(--primary-color);  
    transition: background-color 500ms cubic-bezier(0.55, 0, 0.1, 1);  
}


a,
a:hover {
    color: var(--text-color);
}

p {
    word-break: break-word;
    hyphens: auto;
    font-size: 1.25rem;
}

.ovhidden {
    overflow: hidden;
}

.text_2,
.text_2:hover {
    color: var(--text-color-2);
}

code {
    font-family: source-code-pro, Consolas, Menlo, Monaco, 'Courier New', monospace;
}

.cursor__dot div {
    z-index: 999999 !important;
}

.cursor__dot div:last-child {
    filter: invert(1);
    background-color: var(--primary-color) !important;
}

.cursor__dot div:first-child {
    filter: invert(1);
    background-color: var(--overlay-color) !important;
}

.color_pr {
    color: var(--primary-color) !important;
}

.color_sec {
    color: var(--secondary-color);
}

/* Lottie Color Override */
.lottie-color-override path {
    stroke: var(--secondary-color);          
}

/* React newline */
.newline {
    white-space: pre-wrap;
}