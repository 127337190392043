.image-container {
    /* position: relative;
    transform: translate3d(0, 0, 0); */
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    isolation: isolate;

    &::after {
        content: '';
        background-color: var(--secondary-color);
        position: absolute;
        inset: 0;
        transform: scale3d(1, 1, 1);
        transform-origin: left;
        will-change: transform;
        z-index: 20;
    }

    &[data-visible='true']::after {
        transform: scale3d(0, 1, 1);
        transition: transform 0.5s ease;    
    }
}

.image-element {
    /* width: 100%; */
    height: auto;
    grid-column: 1;
    grid-row: 1;

    

    &[data-cover='true'] {
        height: 100%;
        object-fit: cover;
    }

    
}

/* .image-skeleton {
    content: '';
    background-color: var(--secondary-color);
    position: absolute;
    inset: 0;
    transform: scale3d(1, 1, 1);
    transform-origin: left;
    will-change: transform;
    z-index: 20;
} */

/* .image-skeleton[data-visible='true'] {
    transform: scale3d(0, 1, 1);
    transition: transform 0.5s ease;    
} */