@media only screen and (max-width: 991px) {
    .s_c {
        padding-top: 40px;
    }
}

/* class add by react-transition-group (CSSTransition in routes.js) */

/*  */
.page-enter {
    opacity: 0;
    transform: scale(48.763%) translateY(4.8763%);;
}

.page-enter-active {
    opacity: 1;
    /* transform: translateY(0%); */
    transform: scale(1) translateY(0);;
    transition: all 333ms ease-out;
}

/* .page-enter-done {} */

.page-exit {
    /* transform: translateY(0%); */
    /* position: absolute; */
    /* left: 0;
    right: 0;
    top: 0; */
    /* transform: scale(0.87) translateY(87%); */
    opacity: 0.9;
}

.page-exit-active {
    /* position: absolute; */
    /* left: 0;
    right: 0;
    top: 0; */
    /* transform: scale(0%) translateY(-100%); */
    /* transition: all 400ms ease-out; */
    opacity: 0;
}