#fork_container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    animation: rainbow 5s infinite linear;
    text-align: center;    
}

#ｆｏｒｋ {
    animation: ｆｏｒｋ 1s infinite linear;
    position: absolute;
    margin: auto;
    max-width: 75vw;
    max-height: 75vh;
    z-index: 100;
    /* width: 50%; */
    top: 15%;
    left: 25%;    
}

#fork_title {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 10rem;
    font-family: Serif; 
    z-index: 10;
    opacity: 75%;
}

@keyframes ｆｏｒｋ {
    0% {
        transform: rotate(0) scale(0.7);
        filter: drop-shadow(16px 16px 20px red) blur(0);
    }

    50% {
        transform: rotate(180deg) scale(1.2);
        filter: drop-shadow(316px 36px 20px blue) blur(3px) invert(70%);
    }

    100% {
        transform: rotate(360deg) scale(0.7);
        filter: drop-shadow(16px 16px 20px red) blur(0);
    }
}


@keyframes rainbow {

    100%,
    0% {
        background-color: rgb(255, 0, 0);
    }

    8% {
        background-color: rgb(255, 127, 0);
    }

    16% {
        background-color: rgb(255, 255, 0);
    }

    25% {
        background-color: rgb(127, 255, 0);
    }

    33% {
        background-color: rgb(0, 255, 0);
    }

    41% {
        background-color: rgb(0, 255, 127);
    }

    50% {
        background-color: rgb(0, 255, 255);
    }

    58% {
        background-color: rgb(0, 127, 255);
    }

    66% {
        background-color: rgb(0, 0, 255);
    }

    75% {
        background-color: rgb(127, 0, 255);
    }

    83% {
        background-color: rgb(255, 0, 255);
    }

    91% {
        background-color: rgb(255, 0, 127);
    }
}